<script>
import {find, updateSite, sendConfigSite} from '@/domain/sites/services'
import DefaultMixin from '../mixin'
import SlLoading from "components/layout/components/Loading.vue"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import {UCheckbox, UProgress} from "uloc-vue"
import ErpSelect from "@/plugins/uloc-erp/components/form/select"
import lodingProgress from "@/reuse/loading/loadingMixin"

export default {
  name: 'Sites',
  mixins: [DefaultMixin, lodingProgress],
  components: {ErpSelect, ErpInput, ErpSField, ECol, ERow, SlLoading, UCheckbox, UProgress},
  data() {
    return {
      loading: true,
      salvando: false,
      autorizar: false,
      site: null,
      cache: {
        enableProgress: true,
        progressDesc: 'Salvando parâmetros',
        success: null
      }
    }
  },
  computed: {},
  mounted() {
    this.load()
  },
  destroyed() {
  },
  methods: {
    load() {
      this.loading = true
      find(this.$route.params.id)
          .then(response => {
            this.site = response.data
            if (!this.site.config) {
              this.site.config = {}
            }
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    save () {
      if (!this.autorizar) {
        this.dg('Você precisa concordar para prosseguir com a alteração', 'Clique em Ok para continuar', 'Ok', 'c-error')
        return
      }
      const data = JSON.parse(JSON.stringify(this.site))
      this.salvando = true
      this.cache.success = null
      this.cache.enableProgress = true
      this.startLoading()
      updateSite(this.site.id, data)
          .then(response => {
            this.autorizar = false
            this.stopLoading()
            this.cache.progressDesc = 'Enviando e aplicando atualizações no website'
            this.startLoading()
            sendConfigSite(this.site.id)
                .then(response => {
                  this.cache.success = true
                  setTimeout(() => {
                    this.salvando = false
                  }, 3000)
                })
                .catch(error => {
                  this.cache.progressDesc = 'Falha :('
                  this.cache.enableProgress = false
                  setTimeout(() => {
                    this.salvando = false
                  }, 3000)
                  this.alertApiError(error)
                })
          })
          .catch(error => {
            this.stopLoading()
            this.alertApiError(error)
          })
    }
  },
  meta: {
    title: 'Sites',
    name: 'Sites'
  }
}
</script>

<template>
  <div class="full-height flex items-center justify-center" v-if="loading">
    <sl-loading/>
  </div>
  <div class="site-edit-container e-input-modern size1" v-else>
    <div class="site-edit-form">
      <div class="sl-alert sl-alert-warning m-b text-negative"><strong>Muito cuidado!</strong> Alterações nesta área sem o devido conhecimento pode prejudicar o funcionamento do website</div>
      <div class="u-window m-b-lg">
        <div class="wrapper">
          <e-row mr>
            <e-col>
              <erp-s-field label="Nome do site">
                <erp-input v-model="site.nome"/>
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field label="URL">
                <erp-input v-model="site.url"/>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row mr>
            <e-col>
              <erp-s-field label="URL do webhook">
                <erp-input v-model="site.urlWebhook"/>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row mr>
            <e-col>
              <erp-s-field label="Token">
                <erp-input v-model="site.token"/>
              </erp-s-field>
            </e-col>
          </e-row>
        </div>
      </div>
      <e-row class="m-b" mr>
        <e-col>
          <erp-s-field label="Título do site" helper="Até 255 caracteres" helper-position="lb">
            <erp-input v-model="site.config.SL_SITE_TITLE"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-b" mr>
        <e-col>
          <erp-s-field label="Descrição do site" helper="Até 255 caracteres" helper-position="lb">
            <erp-input v-model="site.config.SL_SITE_DESCRIPTION"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Tema do site">
            <erp-input v-model="site.config.THEME"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Cor principal">
            <erp-input v-model="site.config.SITE_COR_PRINCIPAL" type="color"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Cor secundária">
            <erp-input v-model="site.config.SITE_COR_SECUNDARIA" type="color"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="URL Logomarca">
            <erp-input v-model="site.config.LOGO_URL"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="URL Favicon">
            <erp-input v-model="site.config.FAVICON_URL"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Modelo Cabeçalho Website">
            <erp-input v-model="site.config.WEBSITE_HEADER"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Modelo Home Website">
            <erp-input v-model="site.config.WEBSITE_HOME"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Modelo Footer Website">
            <erp-input v-model="site.config.WEBSITE_FOOTER"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Folha de estilo personalizada">
            <erp-input v-model="site.config.WEBSITE_CSS"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="GOOGLE_RECAPTCHA_SITE_KEY">
            <erp-input v-model="site.config.GOOGLE_RECAPTCHA_SITE_KEY"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="GOOGLE_RECAPTCHA_SECRET">
            <erp-input v-model="site.config.GOOGLE_RECAPTCHA_SECRET"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col><h3 class="font-16">Mídias sociais e contato</h3></e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Telefone">
            <erp-input v-model="site.config.CONTATO_TELEFONE"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="WhatsApp">
            <erp-input v-model="site.config.CONTATO_WHATSAPP"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Instagram">
            <erp-input v-model="site.config.CONTATO_INSTAGRAM"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Linkedin">
            <erp-input v-model="site.config.CONTATO_LINKEDIN"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Endereço">
            <erp-input v-model="site.config.CONTATO_ENDERECO"/>
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 120px">
          <erp-s-field label="Número">
            <erp-input v-model="site.config.CONTATO_ENDERECO_NUMERO"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Bairro">
            <erp-input v-model="site.config.CONTATO_ENDERECO_BAIRRO"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="CEP">
            <erp-input v-model="site.config.CONTATO_CEP"/>
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 120px">
          <erp-s-field label="Cidade">
            <erp-input v-model="site.config.CONTATO_ENDERECO_CIDADE"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="UF">
            <erp-input v-model="site.config.CONTATO_ENDERECO_UF"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col><h3 class="font-16">Definições do website</h3></e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Bloquear acesso a leilões e lotes encerrados">
            <erp-select v-model="site.config.BLOQUEAR_ENCERRADOS" :options="[
                {label: 'Sim', value: 1},
                {label: 'Não', value: 0},
            ]" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Mostrar leilões encerrados" icon-help="Irá listar os leilões encerados. Se 'Bloquear acesso a leilões e lotes encerrados' estiver ativo, os leilão não serão clicáveis.">
            <erp-select v-model="site.config.MOSTRAR_LEILOES_ENCERRADOS" :options="[
                {label: 'Sim', value: 1},
                {label: 'Não', value: 0},
            ]" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Ativar botão do whatsapp flutuante">
            <erp-select v-model="site.config.BOTAO_WHATSAPP_FLUTUANTE" :options="[
                {label: 'Sim', value: 1},
                {label: 'Não', value: 0},
            ]" />
          </erp-s-field>
        </e-col>
      </e-row>
      <div class="m-t">
        <div class="m-b-sm">
          <div class="sl-alert">
            <div class="flex items-center">
              <u-checkbox v-model="autorizar" color="black" /> <div class="m-t-xxs m-l-xs">Concordo que ao salvar, os dados configurados aqui serão transmitidos para o site <strong>{{site.nome}}</strong></div>
            </div>
          </div>
        </div>
        <u-btn @click="save" :loading="salvando" label="Salvar alterações" color="green" class="sl-btn" no-caps />
        <u-btn @click="$router.push({name: 'sites.lista'})" label="Cancelar e voltar" color="white" class="sl-btn text-grey m-l-sm" no-caps />
      </div>
    </div>
    <div v-if="salvando" ref="floatProgress" class="app-float-progress hidden-print" style="min-width: 250px">
      <div v-if="!cache.success">
        {{cache.progressDesc}}
        <div>
          <u-progress v-if="cache.enableProgress" class="m-t" :percentage="loadingProgress" :animate="true"
                      :color="loadingProgress < 99 ? 'positive' : 'positive'" height="10px"/>
        </div>
      </div>
      <div v-else>
        <div class="flex items-center">
          <div class="success-checkmark" style="zoom: 0.3">
            <div class="check-icon">
              <span class="icon-line line-tip"></span>
              <span class="icon-line line-long"></span>
              <div class="icon-circle"></div>
              <div class="icon-fix"></div>
            </div>
          </div>
          <div class="m-l m-t-n-xs">Parâmetros registrados com sucesso!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
